


//////////////////////////////
// Wind direction converter //
//////////////////////////////


$(document).ready(function(){

    $(".compass-direction").each(function() {

        var windDegrees = $(this).data("wind-deg");

        var windDirection = [[337, 360, "N"], [0, 23, "N"], [24, 68, "NE"], [69, 113, "E"], [114, 158, "SE"], [159, 203, "S"], [204, 248, "SW"], [249, 293, "W"], [294, 336, "NW"]]

        for (let i = 0; i < windDirection.length; i++) {

            if (windDegrees >= windDirection[i][0] && windDegrees <= windDirection[i][1]) {
                $(this).text(windDirection[i][2]);
                break;
            }

        }
    });

});


///////////////////////
// Units Converstion //
///////////////////////


// Measurements that need converstion
// Temperatures
// Wind speed and gusts
// Precipitation volumes


///////////////
// To metric //
///////////////

function toMetric(element) {

    // Temperature change
    $(".temperature").each(function() {

        var temp = $(this).data("temp");

        $(this).text(parseFloat(temp).toFixed());

    });

    // Temperature Unit
    $(".temperature-unit").each(function() {

        $(this).text("C")

    });

    // Speed change
    $(".speed").each(function() {

        var speed = $(this).data("speed");

        $(this).text((parseFloat(speed) * 3.6).toFixed());

    });

    // Speed Unit
    $(".speed-unit").each(function() {

        $(this).text("km/h")

    });

    // Volume change
    $(".volume").each(function() {

        var volume = $(this).data("volume");

        $(this).text((parseFloat(volume) / 10).toFixed(1));

    });

    // Volume Unit
    $(".volume-unit").each(function() {

        $(this).text("cm")

    });

    // Distance change
    $(".distance").each(function() {

        var distance = $(this).data("distance");

        $(this).text((parseFloat(distance) / 1000).toFixed(1));

    });

    // Distance Unit
    $(".distance-unit").each(function() {

        $(this).text("km")

    });

    // Add cookie
    //
    $(".page-header .btn").removeClass('selected');
    $(element).addClass('selected');


}


///////////////
// To Imperial //
///////////////

function toImperial(element) {

    // Temperature change
    $(".temperature").each(function() {

        var temp = $(this).data("temp");

        $(this).text(((parseFloat(temp) * 9/5) + 32).toFixed());

    });

    // Temperature Unit
    $(".temperature-unit").each(function() {

        $(this).text("F")

    });

    // Speed change
    $(".speed").each(function() {

        var speed = $(this).data("speed");
        console.log(speed);
        console.log("float " + parseFloat(speed));

        $(this).text((parseFloat(speed) * 2.237).toFixed());

    });

    // Speed Unit
    $(".speed-unit").each(function() {

        $(this).text("mph")

    });

    // Volume change
    $(".volume").each(function() {

        var volume = $(this).data("volume");

        $(this).text((parseFloat(volume) / 25.4).toFixed(1));

    });

    // Volume Unit
    $(".volume-unit").each(function() {

        $(this).text("in")

    });

    // Distance change
    $(".distance").each(function() {

        var distance = $(this).data("distance");

        $(this).text((parseFloat(distance) / 1609).toFixed(1));

    });

    // Distance Unit
    $(".distance-unit").each(function() {

        $(this).text("mi")

    });

    // Make cookie
    $(".page-header .btn").removeClass('selected');
    $(element).addClass('selected');

}



//////////////////
// Clear inputs //
//////////////////


function clearSearch() {

    $('#data_search').val("");

}


//////////////////////
// Public Functions //
//////////////////////

window.toImperial = toImperial;
window.toMetric = toMetric;
window.clearSearch = clearSearch;